import { InputToggle } from "@clearabee/ui-library-native/dist/Core/Form/Inputs/InputToggle/InputToggle";
import { InputCheckbox } from "@clearabee/ui-library-native/dist/Core/Form/Inputs/InputCheckbox/InputCheckbox";
import { InputList } from "@clearabee/ui-library-native/dist/Core/Form/Inputs/InputList/InputList";
import { InputQuantity } from "@clearabee/ui-library-native/dist/Core/Form/Inputs/InputQuantity/InputQuantity";
import { InputImage } from "@clearabee/ui-library-native/dist/Core/Form/Inputs/InputImage/InputImage";
import { InputButtonText } from "@clearabee/ui-library-native/dist/Core/Form/Inputs/InputButtonText/InputButtonText";

import { InputText } from "./InputText/InputText";
import { InputDate } from "./InputDate/InputDate";
import { InputRangedDate } from "./InputRangedDate/InputRangedDate";
import { InputSelect } from "./InputSelect/InputSelect";

export const Input = {
  Date: InputDate,
  RangedDate: InputRangedDate,
  Text: InputText,
  Toggle: InputToggle,
  Select: InputSelect,
  Checkbox: InputCheckbox,
  List: InputList,
  Quantity: InputQuantity,
  Image: InputImage,
  ButtonText: InputButtonText,
};
